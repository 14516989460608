import {
  query,
  collection,
  orderBy,
  where,
  onSnapshot,
} from 'firebase/firestore';
import React, { ReactNode, useEffect } from 'react';
import Navbar from 'src/components/General/Navbar';
import { db } from 'src/firebase';
import AccountStore from 'src/stores/AccountStore';
import CompanyStore from 'src/stores/CompanyStore';
import TicketStore from 'src/stores/TicketStore';
import UserStore from 'src/stores/UserStore';

interface IAppLayoutProps {
  children: ReactNode;
}

function AppLayout({ children }: IAppLayoutProps) {
  const { currentUser } = UserStore();
  const { setTickets } = TicketStore();
  const { accounts, setAccounts } = AccountStore();
  const { setCompanies } = CompanyStore();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (currentUser) {
      const accountQuery = collection(db, 'users');

      const accountUnsub = onSnapshot(accountQuery, (snapshot) => {
        setAccounts(snapshot.docs.map((d) => ({ ...d.data(), id: d.id })));
      });

      return accountUnsub;
    }
  }, [currentUser]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (currentUser) {
      const userInstance = accounts.find(
        (account) => account.id === currentUser.uid
      );

      let ticketQuery;
      // Only fetch all if the user is an admin
      if (userInstance?.type === 'SolX - Admin' || userInstance?.type === 'SolX - Account Manager') {
        ticketQuery = query(
          collection(db, 'tickets'),
          where('deleted', '==', false),
          orderBy('createdAt', 'desc')
        );
      } else {
        ticketQuery = query(
          collection(db, 'tickets'),
          // Only fetch tickets where he is participant
          where('participants', 'array-contains', currentUser.uid),
          where('deleted', '==', false),
          orderBy('createdAt', 'desc')
        );
      }

      const ticketUnsub = onSnapshot(ticketQuery, (snapshot) => {
        setTickets(snapshot.docs.map((d) => ({ ...d.data(), id: d.id })));
      });

      const companyQuery = collection(db, 'companies');

      const companyUnsub = onSnapshot(companyQuery, (snapshot) => {
        setCompanies(snapshot.docs.map((d) => ({ ...d.data(), id: d.id })));
      });

      const unsub = () => {
        ticketUnsub();
        companyUnsub();
      };

      return unsub;
    }
  }, [currentUser, accounts]);

  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto pt-28 px-2 sm:px-4 lg:px-6 pb-12">
        {children}
      </div>
    </>
  );
}

export default AppLayout;
