import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface IText {
  type: string; // 'h1' | 'h2' | 'p' | 'fp'
  classnames?: string;
  children: ReactNode;
}

function Text({ type, classnames, children }: IText) {
  let textStyle = 'font-raleway text-[14px]';

  if (type === 'h1') {
    textStyle = 'font-raleway text-[30px] font-bold';
  } else if (type === 'h2') {
    textStyle = 'font-raleway text-[20px] font-bold';
  } else if (type === 'fp') {
    textStyle = 'font-raleway text-[12px] font-normal';
  }

  return <p className={classNames(textStyle, classnames)}>{children}</p>;
}

Text.defaultProps = {
  classnames: null,
};

export default Text;
