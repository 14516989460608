import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from 'src/firebase';
import UserStore from './stores/UserStore';
import Tickets from './pages/Tickets';
import ViewTicket from './pages/ViewTicket';
import Login from './pages/Login';
import Accounts from './pages/Accounts';
import Documents from './pages/Documents';
import Folders from './pages/Folders';
// import FAQs from './pages/FAQs';
import Profile from './pages/Profile';
import CreateTicket from './pages/CreateTicket';
import CreateSubmission from './pages/CreateSubmission';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import UnauthenticatedRoute from './routes/UnauthenticatedRoute';

function App() {
  const { currentUser, setCurrentUser } = UserStore();

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      setCurrentUser(auth.currentUser);
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<UnauthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/" element={<Login />} />
          </Route>
          <Route
            path="/tickets"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/tickets" element={<Tickets />} />
          </Route>
          <Route
            path="/tickets/:id"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/tickets/:id" element={<ViewTicket />} />
          </Route>
          <Route
            path="/accounts"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/accounts" element={<Accounts />} />
          </Route>
          <Route
            path="/documents"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/documents" element={<Documents />} />
          </Route>
          <Route
            path="/documents/:id"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/documents/:id" element={<Folders />} />
          </Route>
          {/* <Route
            path="/faqs"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/faqs" element={<FAQs />} />
          </Route> */}
          <Route
            path="/profile"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route
            path="/profile/:id"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/profile/:id" element={<Profile />} />
          </Route>
          <Route
            path="/create-ticket"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route path="/create-ticket" element={<CreateTicket />} />
          </Route>
          <Route
            path="/create-submission/:id"
            element={<AuthenticatedRoute valid={!!currentUser} />}
          >
            <Route
              path="/create-submission/:id"
              element={<CreateSubmission />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
