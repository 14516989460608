import React, { useState } from 'react';
import Text from 'src/components/General/Text';
import Select from 'react-select';

interface IDocumentAccountSelectionProps {
  index: number,
  approver: any,
  companyOptions: any[],
  setAccountOptions: (company: string) => any[],
  saveApproverHandler: (approverId: string, instance: any) => void
}

function DocumentAccountSelection({
  index,
  approver,
  companyOptions,
  setAccountOptions,
  saveApproverHandler
}: IDocumentAccountSelectionProps) {
  const [selectedCompany, setSelectedCompany] = useState('');

  return (
    <div className="mb-2" key={approver.id}>
      <Text type="p" classnames="font-bold">
        {`Approver ${index + 1}`}
      </Text>
      <Select
        isSearchable
        name={`Company ${index + 1}`}
        placeholder={`Company ${index + 1}`}
        options={companyOptions}
        onChange={(instance) => setSelectedCompany(instance.value)}
        className="mb-2"
      />
      <Select
        isSearchable
        name={`Approver ${index + 1}`}
        placeholder={`Approver ${index + 1}`}
        options={setAccountOptions(selectedCompany)}
        onChange={(instance) => saveApproverHandler(approver.id, instance)}
      />
    </div>
  );
}

export default DocumentAccountSelection;
