import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import SearchBar from 'src/components/General/SearchBar';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import CTAButton from 'src/components/General/CTAButton';
import TicketStore from 'src/stores/TicketStore';
import AccountStore from 'src/stores/AccountStore';
import UserStore from 'src/stores/UserStore';
import usePagination from 'src/hooks/usePagination';
import { updateDoc, doc } from 'firebase/firestore';
import GlobalStore from 'src/stores/GlobalStore';
import Modal from 'src/components/General/Modal';
import { toast } from 'react-toastify';
import { timestampToJS } from '../../functions';
import { db } from '../../firebase';

function Tickets() {
  const navigation = useNavigate();
  const { showDueTickets, setShowDueTickets } = GlobalStore();
  const { tickets } = TicketStore();
  const { accounts } = AccountStore();
  const { currentUser } = UserStore();
  const [user, setUser] = useState({ type: null });
  const [accountManagers, setAccountManagers] = useState([]);
  const [ticketToBeRemoved, setTicketToBeRemoved] = useState('');

  // Due
  const [dueTicketCount, setDueTicketCount] = useState(0);

  // Search
  const [search, setSearch] = useState('');

  // Pagination
  const {
    currentPage,
    pageCount,
    setAllData: setAllTickets,
    displayedData: displayedTickets,
    prevPageHandler,
    nextPageHandler
  } = usePagination();

  // Get type of current user
  useEffect(() => {
    if (accounts.length > 0) {
      setUser(accounts.find((account) => account.id === currentUser.uid));
    }
  }, [accounts]);

  // Get Due Count
  useEffect(() => {
    const dueTicketLength = tickets.filter(
      (ticket) =>
        ticket.documentsDueDate
        && ticket.documentsDueDate.toDate() <= new Date()
        && !ticket?.resolvedAt
    ).length;

    setDueTicketCount(dueTicketLength);
  }, [tickets]);

  // Get tickets
  useEffect(() => {
    let filteredTickets = tickets;

    // Due
    if (showDueTickets && dueTicketCount > 0) {
      filteredTickets = filteredTickets.filter(
        (ticket) =>
          ticket.documentsDueDate
          && ticket.documentsDueDate.toDate() <= new Date()
          && !ticket?.resolvedAt
      );
    }

    // Search
    if (search) {
      filteredTickets = filteredTickets.filter((ticket) => {
        const status = ticket?.resolvedAt ? 'close' : 'in progress';
        const clientName = accounts.find(
          (a) => a.id === ticket.clientId
        ).company;

        return (
          ticket.title.toLowerCase().includes(search.toLowerCase())
          || clientName.toLowerCase().includes(search.toLowerCase())
          || ticket?.accountManagerId?.label
            .toLowerCase()
            .includes(search.toLowerCase())
          || ticket.id.toLowerCase().includes(search.toLowerCase())
          || timestampToJS(ticket.createdAt)
            .toLowerCase()
            .includes(search.toLowerCase())
          || status.includes(search.toLowerCase())
        );
      });
    }

    setAllTickets(filteredTickets);
  }, [tickets, user, search, showDueTickets, dueTicketCount]);

  // Due tickets toggle
  const toggleShowDueTickets = () => {
    setSearch('');
    setShowDueTickets(!showDueTickets);
  };

  // If the user is an Exora Admin, show account manager select
  useEffect(() => {
    if (user.type === 'SolX - Admin') {
      // Get all Admin and Account Managers to be used for select tag
      const accountManagerList = accounts
        .filter((account) => account.type === 'SolX - Admin' || account.type === 'SolX - Account Manager')
        .map((accountManager) => ({
          label: accountManager.name,
          value: accountManager.id,
        }));

      // Set Account Managers
      setAccountManagers(accountManagerList);
    }
  }, [accounts, user]);

  // Adding/Updating an Account Manager
  const accountManagerHandler = async (
    ticketId: string,
    accountManager: { label: string; value: string }
  ) => {
    const currentTicket = tickets.find((ticket) => ticket.id === ticketId);

    await updateDoc(doc(db, 'tickets', ticketId), {
      // Add an account manager
      accountManagerId: accountManager,

      // Add a participant
      participants: [...currentTicket.participants, accountManager.value],
    });
  };

  // Render Note
  const renderNote = (ticket) => {
    // If ticket is resolved, return nothing
    if (ticket?.resolvedAt) {
      return null;
    }

    if (ticket.activeParticipants) {
      const activeList = ticket.activeParticipants;
      const documentIds = Object.keys(activeList);

      let totalApprovalsNeeded = 0;
      for (let i = 0; i < documentIds.length; i++) {
        if (activeList[documentIds[i]] === currentUser.uid) {
          // If current user is the client of the ticket and is active (for upload), return need documents
          if (currentUser.uid === ticket.clientId) {
            return 'Document/s Needed';
          }

          // If not clientUser, add as an approval needed
          totalApprovalsNeeded++;
        }
      }

      if (totalApprovalsNeeded !== 0) {
        return `${totalApprovalsNeeded} Pending Approval/s`;
      }
    }

    return null;
  };

  const removeTicket = async (id: string) => {
    await updateDoc(doc(db, 'tickets', id), {
      deleted: true,
    });
    toast.success(`Ticket ${id} deleted.`);
  };

  return (
    <AppLayout>
      <Modal
        title="Remove Ticket"
        body="Removing a ticket would render that ticket inaccessible in the app but would still be retained in the database."
        open={!!ticketToBeRemoved}
        setClose={() => setTicketToBeRemoved('')}
        action={() => removeTicket(ticketToBeRemoved)}
      />

      <Text type="h1">My Tickets</Text>
      <div className="flex justify-between items-center">
        <SearchBar
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <CTAButton
          width="w-fit"
          label="Create Ticket"
          onClick={() => navigation('/create-ticket')}
        />

      </div>

      <div className="flex justify-between items-center mt-8 pb-4">
        <Text type="h2">All Tickets</Text>

        <div className="flex justify-center items-center">
          {dueTicketCount > 0 ? (
            <CTAButton
              width="w-fit"
              label={
                showDueTickets
                  ? 'Show All Tickets'
                  : `Show ${dueTicketCount} Due Tickets`
              }
              small
              showPulse
              inverted
              onClick={toggleShowDueTickets}
            />
          ) : null}

          <div className="flex items-center ml-4">
            <div className="h-8 w-8 mr-1 flex justify-center items-center cursor-pointer">
              <svg
                onClick={() => prevPageHandler()}
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left w-4 h-4"
              >
                <polyline points="15 18 9 12 15 6" />
              </svg>
            </div>
            <Text type="p" classnames="p-0 mx-1">
              {currentPage}
              /
              {pageCount}
            </Text>
            <div className="h-8 w-8 ml-1 flex justify-center items-center cursor-pointer">
              <svg
                onClick={() => nextPageHandler()}
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-right w-4 h-4"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <table className="table-auto min-w-full border-t border-grey">
        <thead className="w-full">
          <tr className="w-full flex">
            <th
              scope="col"
              className="flex w-[18%] justify-between items-center px-6 py-3 cursor-pointer"
            >
              <Text type="p">Title</Text>
            </th>

            <th scope="col" className="flex w-[11%] px-6 py-3">
              <Text type="p">Client</Text>
            </th>

            <th scope="col" className="flex w-[15%] px-6 py-3">
              <Text type="p">Ticket No.</Text>
            </th>

            <th
              scope="col"
              className="flex w-[13%] justify-between items-center px-6 py-3 cursor-pointer"
            >
              <Text type="p">Submission date</Text>
            </th>

            <th scope="col" className="flex w-[13%] px-6 py-3">
              <Text type="p">Status</Text>
            </th>

            <th scope="col" className="flex w-[17%] px-6 py-3">
              {user.type === 'SolX - Admin' ? (
                <Text type="p">Account Manager</Text>
              ) : (
                <span className="sr-only">Requirement</span>
              )}
            </th>

            <th scope="col" className="flex w-[13%] px-6 py-3">
              <span className="sr-only">View</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {displayedTickets.map((ticket) => (
            <tr key={ticket.id} className="flex even:bg-white odd:bg-mist">
              <td className="flex w-[18%] px-6 py-2 items-center">
                <Text type="p">{ticket.title}</Text>
              </td>
              <td className="flex w-[11%] px-6 py-2 items-center">
                <Text type="p">
                  {accounts.find((a) => a.id === ticket.clientId)?.company}
                </Text>
              </td>
              <td className="flex w-[15%] px-6 py-2 items-center">
                <Text type="p" classnames="truncate">{ticket.id}</Text>
              </td>
              <td className="flex w-[13%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p">{timestampToJS(ticket.createdAt)}</Text>
              </td>
              <td className="flex w-[13%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p">
                  {ticket?.resolvedAt ? 'Closed' : 'In Progress'}
                </Text>
              </td>
              <td className="flex w-[17%] px-6 py-2 items-center whitespace-nowrap">
                {user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager' ? (
                  <Select
                    className="w-full"
                    classNamePrefix="select"
                    isClearable
                    isSearchable
                    isDisabled={user.type === 'SolX - Account Manager'}
                    name="color"
                    onChange={(instance) =>
                      accountManagerHandler(ticket.id, instance)}
                    options={accountManagers}
                    defaultValue={ticket.accountManagerId}
                  />
                ) : (
                  <Text type="p" classnames="text-red">
                    {renderNote(ticket)}
                  </Text>
                )}
              </td>
              <td className="flex w-[13%] px-6 py-2 justify-end items-center whitespace-nowrap">
                <CTAButton
                  width="w-fit"
                  label="View"
                  small
                  showPulse={ticket.pendingView.indexOf(currentUser.uid) !== -1}
                  onClick={() => navigation(`/tickets/${ticket.id}`)}
                />
                {ticket.resolvedAt && (user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager') ? (
                  <div className="ml-2">
                    <CTAButton
                      width="w-fit"
                      label="Remove"
                      small
                      black
                      onClick={() => setTicketToBeRemoved(ticket.id)}
                    />
                  </div>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AppLayout>
  );
}

export default Tickets;
