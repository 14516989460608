/* eslint-disable no-console */
import create from 'zustand';

interface IGlobalStore {
  showDueTickets: boolean;
  setShowDueTickets: (boolean) => void;
}

const GlobalStore = create<IGlobalStore>((set) => ({
  showDueTickets: false,
  setShowDueTickets: (data: boolean) => {
    set({ showDueTickets: data });
  },
}));

export default GlobalStore;
