import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from 'src/components/General/SearchBar';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import TicketStore from 'src/stores/TicketStore';
import AccountStore from 'src/stores/AccountStore';
import usePagination from 'src/hooks/usePagination';
import { timestampToJS } from '../../functions';

function Documents() {
  const navigation = useNavigate();
  const { tickets } = TicketStore();
  const { accounts } = AccountStore();
  // Search
  const [search, setSearch] = useState('');

  // Pagination
  const {
    currentPage,
    pageCount,
    setAllData: setAllTickets,
    displayedData: displayedTickets,
    prevPageHandler,
    nextPageHandler
  } = usePagination();

  // Get tickets
  useEffect(() => {
    let filteredTickets = tickets.filter(ticket =>
      !!ticket.documentsDueDate || ticket.files?.length > 0);

    // Search
    if (search) {
      filteredTickets = filteredTickets.filter((ticket) => {
        const clientName = accounts.find(
          (a) => a.id === ticket.clientId
        ).company;

        return (
          clientName.toLowerCase().includes(search.toLowerCase())
          || ticket?.accountManagerId?.label
            .toLowerCase()
            .includes(search.toLowerCase())
          || ticket.id.toLowerCase().includes(search.toLowerCase())
          || timestampToJS(ticket.createdAt)
            .toLowerCase()
            .includes(search.toLowerCase())
        );
      });
    }

    setAllTickets(filteredTickets);
  }, [tickets, search]);

  return (
    <AppLayout>
      <Text type="h1">Document Library</Text>
      <SearchBar
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="flex justify-between items-end">
        <Text type="h2" classnames="mt-8 mb-2">
          All Folders
        </Text>

        <div className="flex items-center mb-2">
          <div className="h-8 w-8 mr-1 flex justify-center items-center cursor-pointer">
            <svg
              onClick={() => prevPageHandler()}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
          </div>
          <Text type="p" classnames="p-0 mx-1">
            {currentPage}
            /
            {pageCount}
          </Text>
          <div className="h-8 w-8 ml-1 flex justify-center items-center cursor-pointer">
            <svg
              onClick={() => nextPageHandler()}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </div>
        </div>
      </div>

      <table className="table-auto min-w-full border-t border-grey">
        <thead className="w-full">
          <tr className="w-full flex">
            <th
              scope="col"
              className="flex w-[25%] justify-between items-center px-6 py-3 cursor-pointer"
            >
              <Text type="p">Name</Text>
            </th>
            <th scope="col" className="flex w-[25%] px-6 py-3">
              <Text type="p">Client</Text>
            </th>
            <th
              scope="col"
              className="flex w-[25%] justify-between items-center px-6 py-3 cursor-pointer"
            >
              <Text type="p">Submission Date</Text>
            </th>
            <th scope="col" className="flex w-[25%] px-6 py-3">
              <Text type="p">Account Manager</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {displayedTickets.map((ticket) => (
            <tr key={ticket.id} className="flex even:bg-white odd:bg-mist">
              <td className="flex w-[25%] px-6 py-2 items-center whitespace-nowrap">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path
                    d="M20 5H10.414L8.707 3.293C8.61426 3.2 8.50406 3.12624 8.38273 3.07596C8.2614 3.02568 8.13134 2.99986 8 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V7C22 5.897 21.103 5 20 5Z"
                    fill="#FBB216"
                  />
                </svg>
                <button
                  type="button"
                  className="border-0 focus-ring-0"
                  onClick={() => navigation(`/documents/${ticket.id}`)}
                >
                  <Text type="p" classnames="cursor-pointer hover:underline">
                    {ticket.id}
                  </Text>
                </button>
              </td>
              <td className="flex w-[25%] px-6 py-2 items-center">
                <Text type="p">
                  {accounts.find((a) => a.id === ticket.clientId)?.company}
                </Text>
              </td>
              <td className="flex w-[25%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p">{timestampToJS(ticket.createdAt)}</Text>
              </td>
              <td className="flex w-[25%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p">{ticket?.accountManagerId?.label || 'N/A'}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AppLayout>
  );
}

export default Documents;
