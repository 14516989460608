import { useEffect, useState } from 'react';

function usePagination() {
  // Pagination
  const [pageLimit] = useState(7);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageLimit;
    const lastIndex = currentPage * pageLimit;
    setDisplayedData(allData.slice(startIndex, lastIndex));
  }, [currentPage, allData]);

  // Pagination logic
  useEffect(() => {
    setCurrentPage(1);
    setPageCount(Math.ceil(allData.length / pageLimit));
  }, [allData]);

  const prevPageHandler = () => {
    // Subtract 1 to page
    if (currentPage > 1) {
      // Set to prev page
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPageHandler = () => {
    // Add 1 to page
    if (currentPage < pageCount) {
      // Set to next page
      setCurrentPage(currentPage + 1);
    }
  };

  return {
    currentPage,
    pageCount,
    setAllData,
    displayedData,
    prevPageHandler,
    nextPageHandler
  };
}

export default usePagination;
