import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Text from 'src/components/General/Text';
import InputGroup from 'src/components/General/InputGroup';
import CTAButton from 'src/components/General/CTAButton';
import AccountStore from 'src/stores/AccountStore';
import CompanyStore from 'src/stores/CompanyStore';
import DocumentAccountSelection from '../DocumentAccountSelection';

interface IApprover {
  id: string,
  approverId: string
}

interface IDocumentRequest {
  id: string,
  name: string,
  description: string,
  approvers: IApprover[]
}

interface IDocumentSubmissionProps {
  requestId: string,
  requests: IDocumentRequest[],
  setRequests: (request: IDocumentRequest[]) => void,
  docNumber: number,
  currentTicket: any
}

function DocumentSubmission(
  { requestId, requests, setRequests, docNumber, currentTicket }: IDocumentSubmissionProps
) {
  const { accounts } = AccountStore();
  const { companies } = CompanyStore();

  // Get current document request via request id
  const currentRequest = requests.find(request => request.id === requestId);

  // Options for companies
  const companyOptions = companies
    .map(company => ({ label: company.name, value: company.name }))
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

  // Options for approvers
  const setAccountOptions = (company) => accounts
    .filter(
      account => account.id !== currentTicket.clientId
      && account.id !== currentTicket.accountManagerId.value
      && account.company === company)
    .map(account => ({ label: account.email, value: account.id }))
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

  // ------------------------------Save name to document------------------------------
  const documentNameHandler = (name: string) => {
    // Add the name to the current document submission request
    const updatedRequests = requests.map(request => (
      request.id === requestId ? { ...request, name } : request
    ));

    // Set new state w/ name to requests
    setRequests(updatedRequests);
  };

  // ------------------------------Save description to document------------------------------
  const documentDescriptionHandler = (description: string) => {
    // Add the name to the current document submission request
    const updatedRequests = requests.map(request => (
      request.id === requestId ? { ...request, description } : request
    ));

    // Set new state w/ description to requests
    setRequests(updatedRequests);
  };

  // ------------------------------Save an approver to document------------------------------
  const saveApproverHandler = (
    id: string,
    instance: { label: string, value: string }
  ) => {
    // Save approverId value inside approverId
    const approvers = currentRequest.approvers.map(approver =>
      approver.id === id ? { ...approver, approverId: instance.value } : approver
    );

    // Reflect in requests
    const updatedRequests = requests.map(request => (
      request.id === requestId ? { ...request, approvers } : request
    ));

    // Save new state w/ updated approvers to requests
    setRequests(updatedRequests);
  };

  // ------------------------------Adding and Removing an approver select tag------------------------------
  const addApproverSelectHandler = () => {
    const updatedRequests = requests.map(request => (
      request.id === requestId ? {
        ...request,
        approvers: [...request.approvers, { id: uuidv4(), approverId: '' }]
      } : request
    ));

    // Save new state w/ new approver field to requests
    setRequests(updatedRequests);
  };

  const removeApproverSelectHandler = () => {
    const updatedRequests = requests.map(request => (
      (request.id === requestId) && request.approvers.length > 1 ? {
        ...request,
        approvers: [...request.approvers.slice(0, -1)]
      } : request
    ));

    // Save new state removed approver field to requests
    setRequests(updatedRequests);
  };

  return (
    <div className="rounded border border-grey p-8 mt-8">
      <div>
        <Text type="h2" classnames="mb-4">
          {`${docNumber} Add document submission`}
        </Text>

        <div className="mb-2">
          <div>
            <InputGroup
              type="text"
              label="Document Name:"
              name="documentName"
              value={currentRequest.name}
              onChange={(e) => documentNameHandler(e.target.value)}
            />
          </div>
          <div>
            <InputGroup
              isTextArea
              label="Description:"
              name="documentNote"
              value={currentRequest.description}
              onChange={(e) => documentDescriptionHandler(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          {currentRequest.approvers.map((approver, index) => (
            <DocumentAccountSelection
              index={index}
              approver={approver}
              companyOptions={companyOptions}
              setAccountOptions={setAccountOptions}
              saveApproverHandler={saveApproverHandler}
            />
          ))}
        </div>
        <CTAButton
          classnames="mt-4 mr-3"
          width="w-fit"
          label="Delete last approver"
          onClick={removeApproverSelectHandler}
        />
        <CTAButton
          classnames="mt-4"
          width="w-fit"
          label="Add another approver"
          onClick={addApproverSelectHandler}
        />
      </div>
    </div>
  );
}

export default DocumentSubmission;
