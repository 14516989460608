import React, { useState, useEffect } from 'react';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import CTAButton from 'src/components/General/CTAButton';
import InputGroup from 'src/components/General/InputGroup';
import UserStore from 'src/stores/UserStore';
import AccountStore from 'src/stores/AccountStore';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, updateDoc, doc } from 'firebase/firestore';
import { storage, db } from '../../firebase';

function CreateTicket() {
  // Fields
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  // For user logic
  const { currentUser } = UserStore();
  const { accounts } = AccountStore();
  const [user, setUser] = useState({ type: null });

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Get type of current user
  useEffect(() => {
    if (accounts.length > 0) {
      setUser(accounts.find((account) => account.id === currentUser.uid));
    }
  }, [accounts]);

  const navigate = useNavigate();

  const uploadFilesHandler = async (uploadedFiles: any[], id: string) => {
    const snapshotPromises = [];
    const downloadURLPromises = [];

    // Upload for every file attached
    for (let i = 0; i < files.length; i++) {
      // Create a root reference
      const storageRef = ref(storage, `${id}/${uploadedFiles[i].name}`);

      // Upload File and push the promise to array
      snapshotPromises.push(uploadBytes(storageRef, uploadedFiles[i]));
    }

    // Convert all snapshot promises to snapshot
    const snapshots = await Promise.all(snapshotPromises);

    // Handle Download URLs
    for (let i = 0; i < snapshots.length; i++) {
      downloadURLPromises.push(getDownloadURL(snapshots[i].ref));
    }

    // Convert all url promises to url
    const downloadURLs = await Promise.all(downloadURLPromises);

    // Format
    const formattedDownloadURLS = [];
    for (let i = 0; i < files.length; i++) {
      formattedDownloadURLS.push({
        label: uploadedFiles[i].name,
        url: downloadURLs[i],
      });
    }

    // Add files field to ticket
    await updateDoc(doc(db, 'tickets', id), {
      files: formattedDownloadURLS,
    });
  };

  const submitTicketHandler = async (uploadedFiles: any[]) => {
    // Loading
    setIsLoading(true);

    // Validate title
    if (!title) {
      toast.error('No Title provided', {
        theme: 'colored',
      });
      setIsLoading(false);
      return;
    }

    // Validate description
    if (!description) {
      toast.error('No Description provided', {
        theme: 'colored',
      });
      setIsLoading(false);
      return;
    }

    let clientId;
    if (user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager') {
      // Need to fetch the id of the client who owns the email
      // const client = accounts.find((a) => a.id === selectedClient.value);
      if (!selectedClient) {
        toast.error('No client was specified');
        setIsLoading(false);
        return;
      }
      clientId = selectedClient.value;
    } else {
      // Use the current user id
      clientId = currentUser.uid;
    }

    try {
      // Create new Ticket
      const docRef = await addDoc(collection(db, 'tickets'), {
        clientId,
        createdAt: new Date(),
        title,
        description,
        participants: [clientId],
        pendingView: [],
        resolvedAt: '',
        deleted: false,
      });

      // Upload all files to storage
      if (uploadedFiles.length) {
        await uploadFilesHandler(uploadedFiles, docRef.id);
      }

      // Send email on ticket creation
      const clientEmail = accounts.find((a) => a.id === clientId).email;

      await axios.post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/createTicket',
        {
          clientEmail,
          ticketId: docRef.id,
          ticketName: title,
        }
      );

      // Loading done
      setIsLoading(false);

      // Redirect
      navigate('/tickets');
    } catch (e) {
      // Ticket Creation Failure
      setIsLoading(false);
      toast.error(e.error);
    }
  };

  return (
    <AppLayout>
      <div className="w-[50%] mx-auto">
        <Text type="h1" classnames="text-center mb-1">
          Create Ticket
        </Text>
        <Text type="p" classnames="w-[90%] text-center mx-auto mb-8">
          Always available to serve you, let us know your concern! Submit a
          ticket and an account manager will be assigned to help in your
          problems
        </Text>

        <Text type="fp" classnames="mb-3">
          INFO
        </Text>

        <div className="p-4 border-t border-grey">
          <InputGroup
            type="text"
            label="Title"
            name="title"
            placeholder="Issue Name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <InputGroup
            isTextArea
            label="Description"
            name="description"
            placeholder="Enter description here"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager'
            ? (
              <InputGroup
                label="Client"
                name="client"
                value={selectedClient}
                options={accounts.filter(a => a.type !== 'SolX - Admin' && a.type !== 'SolX - Account Manager').map((a) => ({
                value: a.id,
                label: a.email,
              }))}
                onChange={(option) => setSelectedClient(option)}
              />
          ) : null}
          <InputGroup
            isFiles
            label="Attachments"
            name="attachments"
            placeholder="Enter description here"
            value="a"
            files={files}
            setFiles={setFiles}
          />
          <div className="flex justify-center items-center my-8">
            <CTAButton
              width="w-[100px]"
              label="Cancel"
              onClick={() => navigate('/tickets')}
              inverted
            />
            <CTAButton
              width={isLoading ? 'w-fit' : 'w-[100px]'}
              label="Create"
              classnames="mx-1"
              onClick={() => submitTicketHandler(files)}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default CreateTicket;
