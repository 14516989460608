import React, { useEffect, useState } from 'react';
import SearchBar from 'src/components/General/SearchBar';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import usePagination from 'src/hooks/usePagination';

function Folders() {
  const { id } = useParams();

  // Getting the refs (name and location)
  const [fileRefs, setAllFileRefs] = useState([]);

  // Getting the urls
  const [allURLs, setAllURLs] = useState([]);

  // Combining urls and file refs
  const [unfilteredFiles, setUnfilteredFiles] = useState([]);

  // Search
  const [search, setSearch] = useState('');

  // Pagination
  const {
    currentPage,
    pageCount,
    setAllData: setSearchFilteredFiles,
    displayedData: displayedFiles,
    prevPageHandler,
    nextPageHandler
  } = usePagination();

  // Getting the refs and urls
  useEffect(() => {
    const getFiles = async () => {
      const storage = getStorage();

      // Create a reference under folder of ticket in storage
      const listRef = ref(storage, id);

      // Find all file items. Get name and location
      const res = await listAll(listRef);
      setAllFileRefs(
        res.items.map(file => ({ name: file.name, location: file.fullPath }))
      );

      // Get download urls
      const promises = res.items.map(file => getDownloadURL(file));
      Promise.all(promises).then(urls => {
        setAllURLs(urls);
      });
    };
    getFiles();
  }, []);

  // Combining ref and url
  useEffect(() => {
    if (allURLs.length > 0 && fileRefs.length > 0) {
      const files = fileRefs.map((fileRef, i) => ({ ...fileRef, url: allURLs[i] }));
      setSearchFilteredFiles(files);
      setUnfilteredFiles(files);
    }
  }, [allURLs, fileRefs]);

  // Search
  useEffect(() => {
    const files = unfilteredFiles;

    // If has search input, filter.
    if (search) {
      const filteredFiles = files.filter(file =>
        file.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchFilteredFiles(filteredFiles);
    } else {
      setSearchFilteredFiles(files);
    }
  }, [search, unfilteredFiles]);

  return (
    <AppLayout>
      <div className="flex justify-between items-end">
        <div>
          <Text type="h1">{`Ticket - ${id}`}</Text>
          <SearchBar
            value={search}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div className="flex justify-between items-end">
        <Text type="h2" classnames="mt-8 mb-2">
          All Files
        </Text>

        <div className="flex items-center mb-2">
          <div className="h-8 w-8 mr-1 flex justify-center items-center cursor-pointer">
            <svg
              onClick={() => prevPageHandler()}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
          </div>
          <Text type="p" classnames="p-0 mx-1">
            {currentPage}
            /
            {pageCount}
          </Text>
          <div className="h-8 w-8 ml-1 flex justify-center items-center cursor-pointer">
            <svg
              onClick={() => nextPageHandler()}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right w-4 h-4"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </div>
        </div>
      </div>

      <table className="table-auto min-w-full border-t border-grey">
        <thead className="w-full">
          <tr className="w-full flex">
            <th
              scope="col"
              className="flex w-[100%] justify-between items-center px-6 py-3 cursor-pointer"
            >
              <Text type="p">Name</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {displayedFiles.map((file) => (
            <tr key={file.location} className="flex even:bg-white odd:bg-mist">
              <td className="flex w-[100%] px-6 py-2 items-center whitespace-nowrap">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path
                    d="M14.1877 4.79287C14.2873 4.89248 14.3438 5.02695 14.3438 5.16807V15.4062C14.3438 15.7001 14.1063 15.9375 13.8125 15.9375H3.1875C2.89365 15.9375 2.65625 15.7001 2.65625 15.4062V1.59375C2.65625 1.2999 2.89365 1.0625 3.1875 1.0625H10.2382C10.3793 1.0625 10.5154 1.11895 10.615 1.21855L14.1877 4.79287ZM13.1186 5.41211L9.99414 2.2877V5.41211H13.1186Z"
                    fill="#FBB216"
                  />
                </svg>
                <a
                  href={file.url}
                  target="_blank"
                  rel="noreferrer"
                  className="border-0 focus-ring-0"
                >
                  <Text type="p" classnames="cursor-pointer hover:underline">
                    {file.name}
                  </Text>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AppLayout>
  );
}

export default Folders;
