function addZero(i) {
  if (i < 10) {
    return `0${i}`;
  }
  return i;
}

export function formatChatTime(date) {
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  const seconds = addZero(date.getSeconds());

  const strTime = `${yyyy}/${mm}/${dd} ${hours}:${minutes}:${seconds}`;
  return strTime;
}
