import React, { ReactNode } from 'react';

interface IBlankLayoutProps {
  children: ReactNode;
}

function BlankLayout({ children }: IBlankLayoutProps) {
  return <div className="w-auto h-auto">{children}</div>;
}

export default BlankLayout;
