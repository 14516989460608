import React from 'react';
import classNames from 'classnames';

interface ICTAButton {
  width: string;
  label: string;
  classnames?: string;
  inverted?: boolean;
  disabled?: boolean;
  small?: boolean;
  black?: boolean;
  loading?: boolean;
  showPulse?: boolean;
  onClick: () => void;
}

function CTAButton({
  width,
  label,
  classnames,
  inverted,
  disabled,
  small,
  black,
  loading,
  showPulse,
  onClick,
}: ICTAButton) {
  let buttonState = `${
    black
      ? 'bg-black hover:text-black border-black'
      : 'bg-yellow hover:text-yellow border-yellow'
  } text-white  hover:bg-white 
  `;

  if (disabled || loading) {
    buttonState = `bg-white text-grey 
    border-grey cursor-auto`;
  } else if (inverted) {
    buttonState = `${
      black
        ? 'text-black hover:bg-black border-black'
        : 'text-yellow hover:bg-yellow border-yellow'
    } bg-white 
    hover:text-white`;
  }

  if (small) {
    return (
      <button
        type="button"
        className={classNames(
                    width,
                    buttonState,
                    `py-1 px-2 rounded-md tracking-wide
          font-semibold font-display font-raleway text-xs border-2 
          focus:outline-none focus:shadow-outline 
          shadow-lg uppercase items-center relative`,
                    classnames,
                    loading ? 'cursor-not-allowed' : null
                )}
        onClick={onClick}
        disabled={disabled || loading}
      >
        {loading ? (
          <svg
            className="inline animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
                ) : null}

        {loading ? 'Processing ...' : label}
        {showPulse ? (
          <span className="animate-ping h-2 w-2 rounded-full bg-red absolute top-[-2px] right-[-2px]" />
                ) : null}
      </button>
    );
  }

  return (
    <button
      type="button"
      className={classNames(
                width,
                buttonState,
                `p-2 rounded-md tracking-wide
        font-semibold font-display font-raleway text-sm border-2 
        focus:outline-none focus:shadow-outline 
        shadow-lg uppercase relative`,
                classnames,
                loading ? 'cursor-not-allowed' : null
            )}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <svg
          className="inline animate-spin h-5 w-5 mr-3 ..."
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
            ) : null}

      {loading ? 'Processing ...' : label}

      {showPulse ? (
        <span className="animate-ping h-2 w-2 rounded-full bg-red absolute top-[-2px] right-[-2px]" />
            ) : null}
    </button>
  );
}

CTAButton.defaultProps = {
  classnames: null,
  inverted: false,
  disabled: false,
  small: false,
  black: false,
  loading: false,
  showPulse: false,
};

export default CTAButton;
