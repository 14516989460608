/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth, db } from 'src/firebase';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import CTAButton from 'src/components/General/CTAButton';
import InputGroup from 'src/components/General/InputGroup';
import UserStore from 'src/stores/UserStore';
import AccountStore from 'src/stores/AccountStore';
import CompanyStore from 'src/stores/CompanyStore';
import Modal from 'src/components/General/Modal';

function Profile() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { currentUser, setCurrentUser } = UserStore();
  const { accounts } = AccountStore();
  const { companies } = CompanyStore();

  const [name, setName] = useState(
    id ? accounts.filter((a) => a.id === id)[0].name : ''
  );
  const [email, setEmail] = useState(id ? accounts.filter((a) => a.id === id)[0].email : '');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState<any>();
  const [type, setType] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  // handles edge case where admin is updating someone's info and decided to update his/her own
  useEffect(() => {
    setName(id ? accounts.filter((a) => a.id === id)[0].name : '');
    setEmail(id ? accounts.filter((a) => a.id === id)[0].email : '');
  }, [id]);

  const handleName = (event: any) => {
    setName(event.currentTarget.value);
  };

  const handleEmail = (event: any) => {
    setEmail(event.currentTarget.value);
  };

  const handlePassword = (event: any) => {
    setPassword(event.currentTarget.value);
  };

  const handleCompany = (option: any) => {
    setCompany(option);
  };

  const handleType = (option: any) => {
    setType(option);
  };

  const handleCreate = () => {
    setIsLoading(true);

    const index = companies.findIndex((c) => c.name === company.value);
    if (index === -1) {
      setDoc(doc(db, 'companies', company.value), {
        name: company.value,
      });
    }

    axios
      .post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/createUser',
        {
          name,
          email,
          password,
          company: company.value,
          type: type.value,
        }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        setCompany(null);
        setType(null);
        setName('');
        setEmail('');
        setPassword('');
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const handleUpdate = () => {
    setIsLoading(true);
    axios
      .post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/updateUserInfo',
        {
          id,
          name,
          email,
          password,
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (
          currentUser.uid === id
          && (email !== currentUser.email || password)
        ) {
          toast.success(res.data.message);
          toast.success('Please login again with the new credentials');
          signOut(auth)
            .then(() => {
              setCurrentUser(null);
              navigate('/');
            })
            .catch((error) => toast.error(error.message));
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  return (
    <>
      <Modal
        title="Unauthorized"
        body="Unfortunately, only admins can access this functionality."
        open={
          accounts.filter((a) => a.id === currentUser.uid)[0].type !== 'SolX - Admin'
          && currentUser.uid !== id
        }
        setClose={() => navigate('/tickets')}
        action={() => navigate('/tickets')}
      />
      <AppLayout>
        <div className="w-[50%] mx-auto">
          <Text type="h1" classnames="text-center mb-1">
            {id ? 'Edit' : 'Create'} Profile
          </Text>
          <Text type="p" classnames="w-[70%] text-center mx-auto mb-8">
            Please ensure that either company or user type is not present in the
            options before creating one
          </Text>

          <Text type="fp" classnames="mb-3">
            INFO
          </Text>

          <div className="p-4 border-t border-grey">
            {id ? null : (
              <>
                <InputGroup
                  isCreatable
                  label="Company Name"
                  name="companyName"
                  value={company}
                  options={companies.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  onChange={handleCompany}
                />

                <InputGroup
                  label="User Type"
                  name="userType"
                  value={type}
                  options={[
                    { value: 'SolX - Admin', label: 'SolX - Admin' },
                    { value: 'SolX - Account Manager', label: 'SolX - Account Manager' },
                    { value: 'CC - Legal', label: 'CC - Legal' },
                    { value: 'CC - Engineering', label: 'CC - Engineering' },
                    {
                      value: 'CC - Account Manager',
                      label: 'CC - Account Manager',
                    },
                    { value: 'RES - Legal', label: 'RES - Legal' },
                    { value: 'RES - Engineering', label: 'RES - Engineering' },
                    {
                      value: 'RES - Account Manager',
                      label: 'RES - Account Manager',
                    },
                    {
                      value: 'Third Party - Legal',
                      label: 'Third Party - Legal',
                    },
                  ]}
                  onChange={handleType}
                />
              </>
            )}

            <InputGroup
              type="text"
              label="Name"
              name="name"
              placeholder="John Doe"
              value={name}
              onChange={handleName}
            />

            <InputGroup
              type="email"
              label="Email"
              name="email"
              placeholder="jmart@gmail.com"
              value={email}
              onChange={handleEmail}
            />

            <InputGroup
              type="password"
              label="Password"
              name="password"
              placeholder="Enter password"
              value={password}
              onChange={handlePassword}
            />

            <div className="flex justify-center items-center my-8">
              <CTAButton
                width="w-[100px]"
                label="Cancel"
                onClick={() => navigate('/tickets')}
                inverted
              />
              <CTAButton
                width={isLoading ? 'w-fit' : 'w-[100px]'}
                label="Save"
                classnames="mx-1"
                onClick={id ? handleUpdate : handleCreate}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </AppLayout>
    </>

  );
}

export default Profile;
