/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react';
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@radix-ui/react-accordion';
import Text from 'src/components/General/Text';
import CTAButton from 'src/components/General/CTAButton';
import { updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, db } from 'src/firebase';
import { toast } from 'react-toastify';
import UserStore from 'src/stores/UserStore';
import AccountStore from 'src/stores/AccountStore';
import axios from 'axios';
import DownloadLink from '../DownloadLink';

interface IDocumentApprovers {
  approverId: string;
  hasApproved: boolean;
}

interface IDocumentRequest {
  approvers: IDocumentApprovers[];
  description: string;
  name: string;
  ticketId: string;
  id: string;
  isRevising?: boolean;
  latestUpload?: { label: string; url: string };
}

interface IDocumentItemProps {
  documentRequest: IDocumentRequest;
  index: number;
  currentTicket: any;
  clientId: string;
  fileUploads: any;
  documentApprovalsComplete: boolean;
  setFileUploads(file: any): void;
  numberOfUploaded: number;
  numberOfDocumentRequests: number;
}

function DocumentItem({
  documentRequest,
  index,
  currentTicket,
  clientId,
  fileUploads,
  documentApprovalsComplete,
  setFileUploads,
  numberOfUploaded,
  numberOfDocumentRequests,
}: IDocumentItemProps) {
  // Loading for uploading
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  // Current User
  const { currentUser } = UserStore();
  const { accounts } = AccountStore();

  // Get Exora Account Manager email
  const exoraEmail = accounts.find(
    (account) => account.id === currentTicket.accountManagerId.value
  )?.email;

  // Get next approver
  const nextApprover = documentRequest?.approvers.find(
    (a) => !a.hasApproved
  )?.approverId;
  const approverName = accounts.find(
    (account) => account.id === nextApprover
  )?.name;

  // # of approvers that approved
  const numberOfApprovals = documentRequest?.approvers.filter(
    (a) => a.hasApproved
  ).length;

  // Client email
  const clientEmail = accounts.find(
    (account) => account.id === currentTicket.clientId
  )?.email;

  // Save file to firebase
  const saveDocFileToFirebase = async (docId: string) => {
    setSaveIsLoading(true);

    // Email notif (if 50% or 100%)
    if (numberOfUploaded + 1 >= numberOfDocumentRequests) {
      // 100%
      await axios.post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/uploadedDocuments',
        {
          exoraEmail,
          ticketName: currentTicket.title,
          ticketId: currentTicket.id,
          progress: 100,
        }
      );
    } else if (
      numberOfUploaded + 1 ===
      Math.ceil(numberOfDocumentRequests / 2)
    ) {
      // 50%
      await axios.post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/uploadedDocuments',
        {
          exoraEmail,
          ticketName: currentTicket.title,
          ticketId: currentTicket.id,
          progress: 50,
        }
      );
    }

    // update active participant of the docReq to first approver (or same approver if revision)
    const approvers = [...documentRequest.approvers];
    const succeedingApprover = approvers.find((a) => !a.hasApproved);

    const activeParticipants = { ...currentTicket.activeParticipants };
    activeParticipants[docId] = succeedingApprover.approverId;

    await updateDoc(doc(db, 'tickets', currentTicket.id), {
      activeParticipants,
    });

    const approverEmail = accounts.find(
      (account) => account.id === succeedingApprover.approverId
    )?.email;

    if (documentRequest.isRevising) {
      // notify revision upload
      await axios.post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/notifyRevisionUpload',
        {
          approverEmail,
          exoraEmail,
          ticketName: currentTicket.title,
          ticketId: currentTicket.id,
          documentName: documentRequest.name,
        }
      );
    } else {
      // notify initial approver to approve
      await axios.post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/approvalRequest',
        {
          approverEmail,
          ticketName: currentTicket.title,
          ticketId: currentTicket.id,
          documentName: documentRequest.name,
        }
      );
    }

    const storageRef = ref(
      storage,
      `${currentTicket.id}/${documentRequest.id}/${fileUploads.name}`
    );

    const snapshot = await uploadBytes(storageRef, fileUploads);
    const downloadURL = await getDownloadURL(snapshot.ref);

    await updateDoc(doc(db, 'documentRequests', docId), {
      latestUpload: { label: fileUploads.name, url: downloadURL },
      isRevising: false,
    });

    toast.success('File has been successfully sent to the approver!');
    setSaveIsLoading(false);
  };

  // Approve document
  const approveDocumentHandler = async (docId: string) => {
    const approvers = [...documentRequest.approvers];
    const indexOfApprover = approvers.findIndex((a) => !a.hasApproved);
    approvers[indexOfApprover].hasApproved = true;
    await updateDoc(doc(db, 'documentRequests', docId), {
      approvers,
    });

    // succeeding approver
    const succeedingApprover = approvers.find((a) => !a.hasApproved);

    // Notification for next approver if there is a succeeding approver
    if (succeedingApprover) {
      // find email
      const approverEmail = accounts.find(
        (account) => account.id === succeedingApprover.approverId
      ).email;

      // notify
      await axios.post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/approvalRequest',
        {
          approverEmail,
          ticketName: currentTicket.title,
          ticketId: currentTicket.id,
          documentName: documentRequest.name,
        }
      );
    }

    // update active participant of the docReq to next approver
    const activeParticipants = { ...currentTicket.activeParticipants };
    activeParticipants[docId] = succeedingApprover
      ? succeedingApprover.approverId
      : null;

    await updateDoc(doc(db, 'tickets', currentTicket.id), {
      activeParticipants,
    });

    toast.success('Document has been approved!');

    // check if all approvers are done. If done, mark document complete
    if (!approvers.find((a) => !a.hasApproved)) {
      await updateDoc(doc(db, 'documentRequests', docId), {
        completed: true,
      });
    }

    if (
      documentApprovalsComplete &&
      documentRequest.approvers.filter((approver) => !approver.hasApproved)
        .length === 0
    ) {
      await axios.post(
        'https://us-central1-exora-crm-f9967.cloudfunctions.net/notifyCompleteApproval',
        {
          clientEmail,
          exoraEmail,
          ticketName: currentTicket.title,
          ticketId: currentTicket.id,
        }
      );
    }
  };

  const declineDocumentHandler = async (docId: string) => {
    await updateDoc(doc(db, 'documentRequests', docId), {
      isRevising: true,
    });

    // Notification
    await axios.post(
      'https://us-central1-exora-crm-f9967.cloudfunctions.net/revisionRequestClient',
      {
        clientEmail,
        ticketName: currentTicket.title,
        ticketId: currentTicket.id,
        documentName: documentRequest.name,
      }
    );

    await axios.post(
      'https://us-central1-exora-crm-f9967.cloudfunctions.net/revisionRequestAdmin',
      {
        exoraEmail,
        ticketName: currentTicket.title,
        ticketId: currentTicket.id,
        documentName: documentRequest.name,
      }
    );

    // update active participant of the docReq to be back to client for now
    // When the user uploads again, it will be back to same approver
    const activeParticipants = { ...currentTicket.activeParticipants };
    activeParticipants[docId] = currentTicket.clientId;

    await updateDoc(doc(db, 'tickets', currentTicket.id), {
      activeParticipants,
    });

    toast.success('Revision Request has been sent!');
  };

  return (
    <AccordionItem
      value={`item-${index + 1}`}
      className="border border-grey p-4"
    >
      <AccordionTrigger className="w-full">
        <div className="relative flex justify-between items-center">
          <Text type="p">{documentRequest.name}</Text>
          <div className="flex items-center">
            {/* Only show red design if approver */}
            {nextApprover === currentUser.uid &&
            documentRequest?.latestUpload &&
            !currentTicket?.resolvedAt &&
            !documentRequest?.isRevising ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-4"
              >
                <path
                  d="M19.965 8.521C19.988 8.347 20 8.173 20 8C20 5.621 17.857 3.712 15.479 4.035C14.786 2.802 13.466 2 12 2C10.534 2 9.214 2.802 8.521 4.035C6.138 3.712 4 5.621 4 8C4 8.173 4.012 8.347 4.035 8.521C2.802 9.215 2 10.535 2 12C2 13.465 2.802 14.785 4.035 15.479C4.01193 15.6517 4.00024 15.8258 4 16C4 18.379 6.138 20.283 8.521 19.965C9.214 21.198 10.534 22 12 22C13.466 22 14.786 21.198 15.479 19.965C17.857 20.283 20 18.379 20 16C20 15.827 19.988 15.653 19.965 15.479C21.198 14.785 22 13.465 22 12C22 10.535 21.198 9.215 19.965 8.521ZM10.955 16.416L7.288 12.702L8.712 11.298L10.969 13.584L15.296 9.29L16.704 10.71L10.955 16.416V16.416Z"
                  fill="#FF2B2B"
                />
              </svg>
            ) : null}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="transition-all delay-100 rotate-270"
            >
              <path
                d="M13.333 5.3335L7.99967 10.6668L2.66634 5.3335"
                stroke="#393939"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {(!documentRequest?.latestUpload || documentRequest?.isRevising) &&
          clientId === currentUser.uid ? (
            <span className="animate-ping h-2 w-2 rounded-full bg-red absolute top-[-2px] right-[-2px]" />
          ) : null}
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <Text type="p" classnames="mt-4 mb-4">
          {documentRequest.description}
        </Text>
        {documentRequest?.latestUpload && !currentTicket?.resolvedAt && (
          <Text type="p" classnames="mt-4 mb-4">
            <strong>
              {numberOfApprovals === documentRequest.approvers.length
                ? 'Approval Process Complete'
                : `Pending approval:
                  ${approverName}
                  (${numberOfApprovals}/${documentRequest.approvers.length})`}
            </strong>
          </Text>
        )}
        {documentRequest?.latestUpload && (
          <DownloadLink
            url={documentRequest.latestUpload.url}
            label={documentRequest.latestUpload.label}
          />
        )}
        {currentTicket.clientId === currentUser.uid &&
          documentRequest?.isRevising && (
            <>
              <hr className="mt-4" />
              <Text type="p" classnames="mt-4 mb-4">
                Revision Needed
              </Text>
            </>
          )}
        {/* Show upload button if current user is the ticket client and file is not yet uploaded or needs revising */}
        {currentTicket.clientId === currentUser.uid &&
          (documentRequest?.isRevising || !documentRequest?.latestUpload) && (
            <div className="flex justify-between mt-4">
              <input
                type="file"
                className="text-sm"
                onChange={(e) => setFileUploads(e.target.files[0])}
              />
              <CTAButton
                small
                loading={saveIsLoading}
                width="w-fit"
                label="Save"
                disabled={!fileUploads}
                onClick={() => saveDocFileToFirebase(documentRequest.id)}
              />
            </div>
          )}
        {/* Only show approval buttons if approver, file is uploaded, and user done revising */}
        {nextApprover === currentUser.uid &&
          !currentTicket?.resolvedAt &&
          documentRequest?.latestUpload &&
          !documentRequest?.isRevising && (
            <div className="flex justify-start mt-4">
              <CTAButton
                small
                inverted
                width="w-fit"
                label="Request Revision"
                classnames="mr-3"
                onClick={() => declineDocumentHandler(documentRequest.id)}
              />
              <CTAButton
                small
                width="w-fit"
                label="Approve"
                onClick={() => approveDocumentHandler(documentRequest.id)}
              />
            </div>
          )}
      </AccordionContent>
    </AccordionItem>
  );
}

export default DocumentItem;
