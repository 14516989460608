import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import CTAButton from 'src/components/General/CTAButton';
import InputGroup from 'src/components/General/InputGroup/InputGroup';
import BlankLayout from 'src/layouts/BlankLayout';
import { auth } from '../../firebase';
import UserStore from '../../stores/UserStore';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const { setCurrentUser } = UserStore();

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        setCurrentUser(user.user);
        navigate('/tickets');
      })
      .catch((error) => toast.error(error.message));
  };

  const handleEmail = (event: any) => {
    setEmail(event.currentTarget.value);
  };

  const handlePassword = (event: any) => {
    setPassword(event.currentTarget.value);
  };

  return (
    <BlankLayout>
      <ToastContainer />
      <div className="lg:flex">
        <div className="min-h-screen flex justify-center items-center lg:w-1/2 lg:h-auto xl:max-w-screen-sm">
          <div className="w-11/12 px-12 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl">
            <h2
              className="text-center text-4xl text-black font-display font-semibold font-raleway lg:text-left xl:text-5xl
                    xl:text-bold"
            >
              Log in
            </h2>
            <div className="mt-12">
              <InputGroup
                type="email"
                label="Email"
                name="email"
                placeholder="jmart@gmail.com"
                value={email}
                onChange={handleEmail}
              />
              <InputGroup
                type="password"
                label="Password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePassword}
              />
              <div className="mt-10">
                <CTAButton
                  width="w-full"
                  label="Log in"
                  onClick={handleSignIn}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:w-1/2 lg:flex items-center justify-center bg-yellow flex-1 h-screen">
          <img src="assets/solx-logo-white.png" alt="SolX" />
        </div>
      </div>
    </BlankLayout>
  );
}

export default Login;
