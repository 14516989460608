import React from 'react';

interface IDownloadLink {
  url: string,
  label: string
}

function DownloadLink({ url, label }: IDownloadLink) {
  return (
    <div className="flex mb-1">
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-2"
      >
        <path
          d="M14.1877 4.79287C14.2873 4.89248 14.3438 5.02695 14.3438 5.16807V15.4062C14.3438 15.7001 14.1063 15.9375 13.8125 15.9375H3.1875C2.89365 15.9375 2.65625 15.7001 2.65625 15.4062V1.59375C2.65625 1.2999 2.89365 1.0625 3.1875 1.0625H10.2382C10.3793 1.0625 10.5154 1.11895 10.615 1.21855L14.1877 4.79287ZM13.1186 5.41211L9.99414 2.2877V5.41211H13.1186Z"
          fill="#FBB216"
        />
      </svg>

      <a
        className="font-raleway text-[14px] font-normal cursor-pointer hover:underline"
        target="_blank"
        rel="noreferrer"
        href={url}
      >
        {label}
      </a>
    </div>
  );
}

export default DownloadLink;
