/* eslint-disable @typescript-eslint/indent */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StepProgressBar from 'react-step-progress';
import {
  Avatar,
  MainContainer,
  ChatContainer,
  ConversationHeader,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import './reactStepProgress.css';
import './chatScope.css';
import { Accordion } from '@radix-ui/react-accordion';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import CTAButton from 'src/components/General/CTAButton';
import AccountStore from 'src/stores/AccountStore';
import UserStore from 'src/stores/UserStore';
import TicketStore from 'src/stores/TicketStore';
import { formatChatTime } from 'src/utils/formatDateTime';
import {
  updateDoc,
  doc,
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  addDoc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, db } from 'src/firebase';
import { timestampToJS } from 'src/functions';
import Modal from 'src/components/General/Modal';
import axios from 'axios';
import InputGroup from 'src/components/General/InputGroup';
import DownloadLink from '../../components/ViewTicket/DownloadLink';
import DocumentItem from '../../components/ViewTicket/DocumentItem';

function ViewTicket() {
  const navigation = useNavigate();
  const { id } = useParams();
  const { tickets } = TicketStore();
  const { accounts } = AccountStore();
  const { currentUser } = UserStore();
  const [chats, setChats] = useState([]);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Get info about current ticket
  const currentTicket = tickets.find((t) => t.id === id);
  // Get info about client
  const clientInfo = accounts.find((a) => a.id === currentTicket.clientId);
  // Get all document requests
  const [documentRequestDetails, setDocumentRequestDetails] = useState([]);
  // File uploads from solx
  const [internalFileUploads, setInternalFileUploads] = useState([]);
  // File uploads from clients
  const [fileUploads, setFileUploads] = useState(null);
  // Get current user
  const [user, setUser] = useState({ type: null });
  // Get number of days before due date
  const [daysLeft, setDaysLeft] = useState(0);
  // Open / close confirmation modal
  const [openModal, setOpenModal] = useState(false);
  // Get number of completed documents
  const numberOfCompleted = documentRequestDetails.filter(
    (docReq) => docReq?.completed
  ).length;

  // Get number of uploaded documents
  const numberOfUploaded = documentRequestDetails.filter(
    (docReq) => docReq?.latestUpload
  ).length;

  // Status Bar
  const steps = [
    {
      label: 'Ticket Sent',
      subtitle: '',
      name: 'Ticket Sent',
      content: '',
    },
    {
      label: 'In Progress',
      subtitle: '',
      name: 'In Progress',
      content: '',
    },
    {
      label: 'Ticket Closed',
      subtitle: '',
      name: 'Ticket Closed',
      content: '',
    },
  ];

  // Get type of current user
  useEffect(() => {
    if (accounts.length > 0) {
      setUser(accounts.find((account) => account.id === currentUser.uid));
    }
  }, [accounts]);

  // Chat functionality
  useEffect(() => {
    const chatQuery = query(
      collection(db, 'chats'),
      where('ticketId', '==', id),
      orderBy('sentAt')
    );

    const unsub = onSnapshot(chatQuery, (snapshot) =>
      setChats(snapshot.docs.map((d) => ({ ...d.data(), id: d.id })))
    );

    const proxyTicket = {
      ...tickets.filter((ticket) => ticket.id === id)[0],
    };
    const pendingViewArray = proxyTicket.pendingView.filter(
      (participant) => participant !== currentUser.uid
    );
    updateDoc(doc(db, 'tickets', id), {
      pendingView: pendingViewArray,
    });

    return unsub;
  }, []);

  // Get document request details
  useEffect(() => {
    const docQuery = query(
      collection(db, 'documentRequests'),
      where('ticketId', '==', id),
      orderBy('documentNumber')
    );

    const unsub = onSnapshot(docQuery, (snapshot) => {
      setDocumentRequestDetails(
        snapshot.docs.map((d) => ({ ...d.data(), id: d.id }))
      );
    });

    return unsub;
  }, []);

  const sendMessage = () => (message) => {
    setChats(chats);
    const proxyTicket = { ...tickets.filter((ticket) => ticket.id === id)[0] };

    delete proxyTicket.id;

    const pendingViewArray = proxyTicket.participants.filter(
      (participant) => participant !== currentUser.uid
    );

    addDoc(collection(db, 'chats'), {
      sentAt: formatChatTime(new Date()),
      message,
      participantId: currentUser.uid,
      ticketId: id,
    });

    updateDoc(doc(db, 'tickets', id), {
      pendingView: pendingViewArray,
    });
  };

  const uploadInternalFilesHandler = async (uploadedFiles: any[]) => {
    setIsLoading(true);
    const snapshotPromises = [];
    const downloadURLPromises = [];

    // Upload for every file attached
    for (let i = 0; i < internalFileUploads.length; i++) {
      // Create a root reference
      const storageRef = ref(storage, `${id}/${uploadedFiles[i].name}`);

      // Upload File and push the promise to array
      snapshotPromises.push(uploadBytes(storageRef, uploadedFiles[i]));
    }

    // Convert all snapshot promises to snapshot
    const snapshots = await Promise.all(snapshotPromises);

    // Handle Download URLs
    for (let i = 0; i < snapshots.length; i++) {
      downloadURLPromises.push(getDownloadURL(snapshots[i].ref));
    }

    // Convert all url promises to url
    const downloadURLs = await Promise.all(downloadURLPromises);

    // Format
    const formattedDownloadURLS = [];
    for (let i = 0; i < internalFileUploads.length; i++) {
      formattedDownloadURLS.push({
        label: uploadedFiles[i].name,
        url: downloadURLs[i],
      });
    }

    const mergedDownloadURLS = currentTicket.files ?
      currentTicket.files.concat(formattedDownloadURLS) :
      [...formattedDownloadURLS];

    // Add files field to ticket
    await updateDoc(doc(db, 'tickets', id), {
      files: mergedDownloadURLS,
    });

    setInternalFileUploads([]);
    setIsLoading(false);
  };

  // Get due date
  useEffect(() => {
    if (currentTicket?.documentsDueDate) {
      const date1 = currentTicket.documentsDueDate.toDate();
      const date2 = new Date();
      const diffTime = date2.getTime() - date1.getTime();
      setDaysLeft(Math.floor(Math.abs(diffTime / (1000 * 3600 * 24))));
    }
  }, [tickets]);

  // Close ticket
  const closeTicketHandler = async () => {
    // Add resolvedAt date
    await updateDoc(doc(db, 'tickets', id), {
      resolvedAt: new Date(),
    });

    const participants = accounts.filter((account) =>
      currentTicket.participants.includes(account.id)
    );

    const participantEmails = [];

    participants.forEach((participant) => {
      participantEmails.push(participant.email);
    });

    await axios.post(
      'https://us-central1-exora-crm-f9967.cloudfunctions.net/notifyTicketClose',
      {
        participantEmails,
        ticketName: currentTicket.title,
        ticketId: currentTicket.id,
      }
    );

    // Navigate to tickets
    navigation('/tickets');
  };

  return (
    <AppLayout>
      <Modal
        title="Close Ticket"
        body="Are you sure you want to close this ticket?"
        open={openModal}
        setClose={() => setOpenModal(false)}
        action={() => closeTicketHandler()}
      />

      <div className="flex justify-between items-center mb-8">
        <Text type="h1">View Ticket</Text>
        {user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager' ?
          currentUser.uid === currentTicket?.accountManagerId?.value &&
          !currentTicket?.resolvedAt && (
            <CTAButton
              black
              inverted
              width="w-fit"
              label="Close Ticket"
              onClick={() => setOpenModal(true)}
            />
          ) : null}
      </div>
      <div className="mb-12">
        <Text type="fp" classnames="text-yellow">
          PROGRESS
        </Text>
        <StepProgressBar
          startingStep={currentTicket.resolvedAt ? 2 : 1}
          primaryBtnClass="hidden"
          secondaryBtnClass="hidden"
          buttonWrapperClass="hidden"
          onSubmit={() => null}
          steps={steps}
        />
      </div>

      <div className="flex">
        <div className="w-3/5 pr-14">
          <div className="mb-8">
            <Text type="fp" classnames="font-cabin text-yellow mb-1">
              TICKET NO.
            </Text>
            <Text type="p">{currentTicket.id}</Text>
          </div>
          {currentTicket?.resolvedAt && (
            <div className="mb-8">
              <Text type="fp" classnames="font-cabin text-yellow mb-1">
                RESOLVED AT
              </Text>
              <Text type="p">{timestampToJS(currentTicket.resolvedAt)}</Text>
            </div>
          )}
          <div className="mb-8">
            <Text type="fp" classnames="font-cabin text-yellow mb-1">
              CLIENT INFORMATION
            </Text>
            <Text type="p">{clientInfo.name}</Text>
            <Text type="p">{clientInfo.type}</Text>
            <Text type="p">
              <strong>{clientInfo.company}</strong>
            </Text>
          </div>
          <div className="mb-8">
            <Text type="fp" classnames="font-cabin text-yellow mb-1">
              TITLE
            </Text>
            <Text type="p">{currentTicket.title}</Text>
          </div>
          <div className="mb-8">
            <Text type="fp" classnames="font-cabin text-yellow mb-1">
              DESCRIPTION
            </Text>
            <Text type="p">{currentTicket.description}</Text>
          </div>
          <div className="mb-8">
            <Text type="fp" classnames="font-cabin text-yellow mb-2">
              IMAGE / FILE ATTACHED
            </Text>
            {currentTicket?.files ? (
              currentTicket.files.map((f) => (
                <DownloadLink key={f.url} url={f.url} label={f.label} />
              ))
            ) : (
              <Text type="p">N/A</Text>
            )}
            {/* Only can upload files if admin and current account manager */}
            {
                ((user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager') &&
                currentUser.uid === currentTicket?.accountManagerId?.value) && (
                <>
                  <br />
                  <InputGroup
                    isFiles
                    label="Attachments (for internal use only)"
                    name="attachments"
                    placeholder="Enter description here"
                    value="a"
                    files={internalFileUploads}
                    setFiles={setInternalFileUploads}
                  />
                  <div className="w-full flex justify-end">
                    <CTAButton
                      width="w-fit"
                      label="Upload Files"
                      small
                      onClick={() => uploadInternalFilesHandler(internalFileUploads)}
                      loading={isLoading}
                    />
                  </div>
                </>
              )
            }
          </div>

          {documentRequestDetails.length > 0 ? (
            <>
              <Text type="h1" classnames="mt-8 mb-4">
                Requested Documents
              </Text>

              <div className="flex justify-between items-center bg-mist border border-grey rounded-t-md p-4">
                <div className="flex items-center">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M20.8643 7.04834C21.0107 7.19482 21.0938 7.39258 21.0938 7.6001V22.6562C21.0938 23.0884 20.7446 23.4375 20.3125 23.4375H4.6875C4.25537 23.4375 3.90625 23.0884 3.90625 22.6562V2.34375C3.90625 1.91162 4.25537 1.5625 4.6875 1.5625H15.0562C15.2637 1.5625 15.4639 1.64551 15.6104 1.79199L20.8643 7.04834V7.04834ZM19.292 7.95898L14.6973 3.36426V7.95898H19.292Z"
                      fill="#404141"
                    />
                  </svg>

                  <Text type="p">
                    {`Documents 
                      (${numberOfCompleted}/${documentRequestDetails.length} done)`}
                  </Text>
                </div>
                <div className="flex items-center">
                  <Text type="p">Due in:</Text>
                  <Text type="p" classnames="ml-2 font-bold">
                    {`${daysLeft} days`}
                  </Text>
                </div>
              </div>
              <Accordion
                type="single"
                defaultValue="item-1"
                onValueChange={() => setFileUploads(null)}
                collapsible
              >
                {documentRequestDetails.map((documentRequest, index) => (
                  <DocumentItem
                    key={documentRequest.id}
                    documentRequest={documentRequest}
                    index={index}
                    currentTicket={currentTicket}
                    clientId={clientInfo.id}
                    fileUploads={fileUploads}
                    documentApprovalsComplete={
                      numberOfCompleted === documentRequestDetails.length - 1
                    }
                    setFileUploads={setFileUploads}
                    numberOfUploaded={numberOfUploaded}
                    numberOfDocumentRequests={documentRequestDetails.length}
                  />
                ))}
              </Accordion>
            </>
          ) : (
            <div>
              {/* Can create submission request if admin and current account manager */}
              {(user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager') &&
                currentUser.uid === currentTicket?.accountManagerId?.value &&
                !currentTicket?.resolvedAt && (
                  <div className="flex mt-8">
                    <CTAButton
                      inverted
                      width="w-fit"
                      label="Create Document Submission"
                      onClick={() => navigation(`/create-submission/${id}`)}
                    />
                  </div>
                )}
            </div>
          )}
        </div>
        <div className="w-2/5 max-h-screen">
          <MainContainer>
            <ChatContainer>
              <ConversationHeader>
                <ConversationHeader.Content userName="Chat" />
              </ConversationHeader>
              <MessageList>
                {chats.map((message) => (
                  <Message
                    key={message.id}
                    model={{
                      message: message.message,
                      sentTime: message.sentAt,
                      sender: accounts.filter(
                        (account) => account.id === message.participantId
                      )[0].name,
                      direction:
                        currentUser.uid === message.participantId
                          ? 'outgoing'
                          : 'incoming',
                      position: 'single',
                    }}
                  >
                    <Avatar
                      src={`https://avatars.dicebear.com/api/initials/${
                        accounts.filter(
                          (account) => account.id === message.participantId
                        )[0].name
                      }.svg`}
                      name={
                        accounts.filter(
                          (account) => account.id === message.participantId
                        )[0].name || 'Anonymous'
                      }
                    />
                    <Message.Header
                      sender={
                        accounts.filter(
                          (account) => account.id === message.participantId
                        )[0].name || 'Anonymous'
                      }
                      sentTime={message.sentAt}
                    />
                  </Message>
                ))}
              </MessageList>
              {/* Only can chat if admin and current account manager OR non-exora participants */}
              {((
                (user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager') &&
                currentUser.uid === currentTicket?.accountManagerId?.value) ||
                (user.type !== 'SolX - Admin' && user.type !== 'SolX - Account Manager')) && (
                <MessageInput
                  placeholder="Type message here"
                  attachButton={false}
                  onSend={sendMessage()}
                />
              )}
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    </AppLayout>
  );
}

export default ViewTicket;
