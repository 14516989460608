/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useDropzone } from 'react-dropzone';
import Text from '../Text';

interface IInputGroup {
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  value?: any;
  min?: string;
  options?: any;
  isMulti?: boolean;
  isCreatable?: boolean;
  isTextArea?: boolean;
  isFiles?: boolean;
  onChange?: (event: any) => void;
  files?: any[];
  setFiles?: (event: any[]) => void;
}

// For select
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    // borderColor: "black !important",
    fontFamily: 'Raleway',
    cursor: 'text',
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    cursor: 'pointer',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingHorizontal: '1.75rem',
  }),
};

function InputGroup({
  type,
  label,
  name,
  placeholder,
  value,
  min,
  options,
  isMulti,
  isCreatable,
  isTextArea,
  isFiles,
  onChange,
  files,
  setFiles,
}: IInputGroup) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const currentFiles = files;
      const newFiles = currentFiles.concat(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            fileType: file.type,
          })
        )
      );
      setFiles(newFiles);
    },
  });

  const removeFile = (fileName: string) => () => {
    const currentFiles = files;
    setFiles(currentFiles.filter((file) => file.name !== fileName));
  };

  const thumbs = files.map((file) => (
    <div
      className="relative inline-flex rounded-[2px] border border-grey mb-[8px] mr-[8px] w-[100px] h-[100px] p-[4px] box-border"
      key={file.name}
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor-pointer absolute top-0 right-1 w-[15px]"
        onClick={removeFile(file.name)}
      >
        <path
          d="M12.0156 22.6621C17.5385 22.6621 22.0156 18.185 22.0156 12.6621C22.0156 7.13926 17.5385 2.66211 12.0156 2.66211C6.49278 2.66211 2.01562 7.13926 2.01562 12.6621C2.01562 18.185 6.49278 22.6621 12.0156 22.6621Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0156 9.66211L9.01562 15.6621"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.01562 9.66211L15.0156 15.6621"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div className="w-full h-full">
        {file.fileType.includes('image') ? (
          <img
            src={file.preview}
            className="block w-auto h-full"
            alt={file.name}
          />
                ) : (
                  <div className="h-full flex flex-col justify-center items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mb-2"
                    >
                      <path
                        d="M20 5H10.414L8.707 3.293C8.61426 3.2 8.50406 3.12624 8.38273 3.07596C8.2614 3.02568 8.13134 2.99986 8 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V7C22 5.897 21.103 5 20 5Z"
                        fill="#FBB216"
                      />
                    </svg>
                    <Text type="fp" classnames="w-full text-center">
                      {file.name}
                    </Text>
                  </div>
                )}
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  if (options) {
    return (
      <div className="mb-4">
        {label ? (
          <p className="block text-sm font-bold font-raleway text-black">
            {label}
          </p>
                ) : null}
        <div className="mt-1 relative rounded-md shadow-sm">
          {isCreatable ? (
            <CreatableSelect
              className="w-full"
              classNamePrefix="select"
              placeholder={placeholder || 'Select ...'}
              isClearable
              isSearchable
              name={name}
              onChange={onChange}
              options={options}
              value={value}
            />
                    ) : (
                      <Select
                        className="w-full"
                        classNamePrefix="select"
                        placeholder={placeholder || 'Select ...'}
                        styles={customStyles}
                        isClearable
                        isSearchable
                        isMulti={isMulti}
                        name={name}
                        onChange={onChange}
                        options={options}
                        value={value}
                      />
                    )}
        </div>
      </div>
    );
  }

  if (isTextArea) {
    return (
      <div className="mb-4">
        {label ? (
          <label
            htmlFor={name}
            className="block text-sm font-bold font-raleway text-black"
          >
            {label}
          </label>
                ) : null}
        <div className="mt-1 relative rounded-md shadow-sm">
          <textarea
            id={name}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className="block w-full h-[100px] px-3 py-[5px] mb-4 font-raleway border border-grey rounded-md"
          />
        </div>
      </div>
    );
  }

  if (isFiles) {
    return (
      <div className="mb-4">
        {label ? (
          <label
            htmlFor={name}
            className="block text-sm font-bold font-raleway text-black"
          >
            {label}
          </label>
                ) : null}
        <div
          {...getRootProps({ className: 'dropzone' })}
          className="border border-dashed border-grey rounded-[4px] p-4 mt-1"
        >
          <input {...getInputProps()} />
          <Text type="p" classnames="text-grey">
            Drag and drop some files here, or click to select files
            {` (${files.length} files attached)`}
          </Text>
        </div>
        <aside className="flex flex-wrap mt-[10px]">{thumbs}</aside>
      </div>
    );
  }

  return (
    <div className="mb-4">
      {label ? (
        <label
          htmlFor={name}
          className="block text-sm font-bold font-raleway text-black"
        >
          {label}
        </label>
            ) : null}
      <div className="mt-1 relative shadow-sm">
        <input
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          min={min}
          onChange={onChange}
          className="block w-full px-3 py-[5px] mb-4 font-raleway border border-grey rounded-[4px]"
        />
      </div>
    </div>
  );
}

InputGroup.defaultProps = {
  label: null,
  type: null,
  placeholder: null,
  value: null,
  min: null,
  options: null,
  isMulti: null,
  isCreatable: null,
  isTextArea: null,
  onChange: null,
  isFiles: null,
  files: [],
  setFiles: null,
};

export default InputGroup;
