/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import SearchBar from 'src/components/General/SearchBar';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import UserStore from 'src/stores/UserStore';
import AccountStore from 'src/stores/AccountStore';
import CompanyStore from 'src/stores/CompanyStore';
import usePagination from 'src/hooks/usePagination';
import CTAButton from 'src/components/General/CTAButton';
import { useNavigate } from 'react-router-dom';

function Accounts() {
  const navigation = useNavigate();
  const { currentUser } = UserStore();
  const { accounts } = AccountStore();
  const { companies } = CompanyStore();

  const [currentSort, setCurrentSort] = useState('');
  const [currentSortDir, setCurrentSortDir] = useState('asc');
  const [search, setSearch] = useState('');

  // Pagination
  const {
    currentPage,
    pageCount,
    setAllData: setAccountData,
    displayedData: displayedAccounts,
    prevPageHandler,
    nextPageHandler
  } = usePagination();

  useEffect(() => {
    let proxyAccountData = accounts.map((account) => ({
      ...account,
      company: companies.filter((company) => company.id === account.company)[0]
        ? companies.filter((company) => company.id === account.company)[0].name
        : account.company,
    }));

    if (search) {
      proxyAccountData = proxyAccountData.filter(
        (account) =>
          account.name.toLowerCase().includes(search.toLowerCase()) ||
          account.email.toLowerCase().includes(search.toLowerCase()) ||
          account.type.toLowerCase().includes(search.toLowerCase()) ||
          account.company.toLowerCase().includes(search.toLowerCase())
      );
    }

    proxyAccountData = proxyAccountData
      .sort((a, b) => {
        let modifier = 1;
        if (currentSortDir === 'desc') modifier = -1;
        if (a[currentSort] < b[currentSort]) return -1 * modifier;
        if (a[currentSort] > b[currentSort]) return 1 * modifier;
        return 0;
      });

    setAccountData(proxyAccountData);
  }, [currentSort, currentSortDir, search, accounts]);

  const sort = (a: string) => () => {
    if (a === currentSort) {
      setCurrentSortDir(currentSortDir === 'asc' ? 'desc' : 'asc');
    }
    setCurrentSort(a);
  };

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <AppLayout>
      <Text type="h1">Accounts</Text>
      <SearchBar value={search} placeholder="Search" onChange={handleSearch} />

      <div className="flex justify-between items-end">
        <Text type="h2" classnames="mt-8 mb-2">
          All Users
        </Text>

        <div className="flex items-center mb-2">
          <button
            type="button"
            className="h-8 w-8 mr-1 flex justify-center items-center cursor-pointer border-none bg-none"
            onClick={prevPageHandler}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
          </button>
          <Text type="p" classnames="p-0 mx-1">
            {currentPage} / {pageCount}
          </Text>
          <button
            type="button"
            className="h-8 w-8 mr-1 flex justify-center items-center cursor-pointer border-none bg-none"
            onClick={nextPageHandler}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right w-4 h-4"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </button>
        </div>
      </div>

      <table className="table-auto min-w-full border-t border-grey">
        <thead className="w-full">
          <tr className="w-full flex">
            <th
              scope="col"
              className="flex w-[24%] justify-between items-center px-6 py-3 cursor-pointer"
              onClick={sort('company')}
            >
              <Text type="p">Company Name</Text>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classNames(
                                    'transition-all delay-100',
                                    currentSortDir === 'desc' && currentSort === 'company'
                                        ? 'rotate-180 '
                                        : null
                                )}
              >
                <path
                  d="M13.333 5.3335L7.99967 10.6668L2.66634 5.3335"
                  stroke="#393939"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </th>

            <th
              scope="col"
              className="flex w-[21%] justify-between items-center px-6 py-3 cursor-pointer"
              onClick={sort('type')}
            >
              <Text type="p">User Type</Text>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classNames(
                                    'transition-all delay-100',
                                    currentSortDir === 'desc' && currentSort === 'type'
                                        ? 'rotate-180 '
                                        : null
                                )}
              >
                <path
                  d="M13.333 5.3335L7.99967 10.6668L2.66634 5.3335"
                  stroke="#393939"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </th>

            <th
              scope="col"
              className="flex w-[24%] justify-between items-center px-6 py-3 cursor-pointer"
              onClick={sort('name')}
            >
              <Text type="p">Representative Name</Text>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classNames(
                                    'transition-all delay-100',
                                    currentSortDir === 'desc' && currentSort === 'name'
                                        ? 'rotate-180 '
                                        : null
                                )}
              >
                <path
                  d="M13.333 5.3335L7.99967 10.6668L2.66634 5.3335"
                  stroke="#393939"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </th>

            <th
              scope="col"
              className="flex w-[21%] px-6 py-3"
              onClick={sort('email')}
            >
              <Text type="p">Email Address</Text>
            </th>

            {
              accounts.filter((a) => a.id === currentUser.uid)[0].type === 'SolX - Admin' ? (
                <th
                  scope="col"
                  className="flex w-[13%] px-6 py-3"
                >
                  <span className="sr-only">View</span>
                </th>
                ) : null
            }
          </tr>
        </thead>
        <tbody>
          {displayedAccounts.map((account) => (
            <tr key={account.id} className="flex even:bg-white odd:bg-mist">
              <td className="flex w-[23%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p" classnames="truncate">{account.company}</Text>
              </td>
              <td className="flex w-[21%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p" classnames="truncate">{account.type}</Text>
              </td>
              <td className="flex w-[23%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p" classnames="truncate">{account.name}</Text>
              </td>
              <td className="flex w-[21%] px-6 py-2 items-center whitespace-nowrap">
                <Text type="p" classnames="truncate">{account.email}</Text>
              </td>
              {
                 accounts.filter((a) => a.id === currentUser.uid)[0].type === 'SolX - Admin' ? (
                   <td className="flex w-[13%] px-6 py-2 justify-end items-center whitespace-nowrap">
                     <CTAButton
                       width="w-fit"
                       label="Edit"
                       small
                       onClick={() => navigation(`/profile/${account.id}`)}
                     />
                   </td>
               ) : null
              }
            </tr>
                    ))}
        </tbody>
      </table>
    </AppLayout>
  );
}

export default Accounts;
