import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IUnauthenticatedRouteProps {
  valid: boolean;
}

function UnauthenticatedRoute({ valid }: IUnauthenticatedRouteProps) {
  return valid ? <Navigate to="/tickets" /> : <Outlet />;
}

export default UnauthenticatedRoute;
