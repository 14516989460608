import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppLayout from 'src/layouts/AppLayout';
import Text from 'src/components/General/Text';
import InputGroup from 'src/components/General/InputGroup';
import CTAButton from 'src/components/General/CTAButton';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { addDoc, collection, updateDoc, doc } from 'firebase/firestore';
import TicketStore from 'src/stores/TicketStore';
import AccountStore from 'src/stores/AccountStore';
import { db } from '../../firebase';
import DocumentSubmission from '../../components/CreateSubmission/DocumentSubmission/DocumentSubmission';

function CreateSubmission() {
  const navigation = useNavigate();
  const { id } = useParams();
  const { tickets } = TicketStore();
  const { accounts } = AccountStore();
  // Get info about current ticket
  const currentTicket = tickets.find((t) => t.id === id);

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // state for list of document request
  const [requests, setRequests] = useState([
    {
      id: uuidv4(),
      name: '',
      description: '',
      approvers: [{ id: uuidv4(), approverId: '' }],
    },
  ]);

  const addDocumentRequest = () => {
    setRequests([
      ...requests,
      {
        id: uuidv4(),
        name: '',
        description: '',
        approvers: [{ id: uuidv4(), approverId: '' }],
      },
    ]);
  };

  const deleteDocumentRequest = () => {
    if (requests.length > 1) {
      setRequests([...requests.slice(0, -1)]);
    }
  };

  // Date object to yyyy-mm-dd
  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  };

  const [dueDate, setDueDate] = useState(formatDate(new Date()));

  // Creating Document Submission Request
  const createRequestHandler = async () => {
    setIsLoading(true);

    // Error Handling
    for (let i = 0; i < requests.length; i++) {
      // Name and Description
      if (!requests[i].name) {
        toast.error('Document Name Missing');
        setIsLoading(false);
        return;
      }

      if (!requests[i].description) {
        toast.error('Document Description Missing');
        setIsLoading(false);
        return;
      }

      // Error if there's a duplicate approver
      const listOfApprovers = requests[i].approvers.map(
        (approver) => approver.approverId
      );
      const uniqueApproversSize = new Set(listOfApprovers).size;
      if (uniqueApproversSize !== listOfApprovers.length) {
        toast.error('Duplicate Approver in a Single Document Found');
        setIsLoading(false);
        return;
      }

      // Error handling for approvers
      for (let j = 0; j < requests[i].approvers.length; j++) {
        // Check if there's a blank approver
        const missingApprover = requests[i].approvers.find(
          (a) => !a.approverId
        );
        if (missingApprover) {
          toast.error('Approver Missing');
          setIsLoading(false);
          return;
        }
      }
    }

    const updatedParticipants = [];

    // List of approvers (for email notification)
    const allTicketApprovers = [];

    // Contains all the first step approvers of each document
    const initialApprovers = [];

    // Contains all documents with value of next active participant (whether approver or uploaded)
    // This is used for the notification (for approvers or uploaders)
    const activeParticipants = {};

    // Get current ticket and add current participants to updatedParticipants
    const ticket = tickets.find((t) => t.id === id);
    for (let t = 0; t < ticket.participants.length; t++) {
      updatedParticipants.push(ticket.participants[t]);
    }

    // To be used for for loop await of adding document requests
    const documentRequestPromises = [];

    for (let i = 0; i < requests.length; i++) {
      // Also add new participants to updatedParticipants
      const approvers = requests[i].approvers.map((a) => a.approverId);

      for (let j = 0; j < approvers.length; j++) {
        updatedParticipants.push(approvers[j]);
        allTicketApprovers.push(approvers[j]);

        if (j === 0) initialApprovers.push(approvers[j]);
      }

      // Add document request details of each documentRequest to documentRequest collection
      const promise = addDoc(collection(db, 'documentRequests'), {
        name: requests[i].name,
        description: requests[i].description,
        approvers: requests[i].approvers.map((a) => ({
          approverId: a.approverId,
          hasApproved: false,
        })),
        ticketId: id,
        isRevising: false,
        documentNumber: i + 1,
      });

      // Store unfulfilled promise result in array
      documentRequestPromises.push(promise);
    }

    // Fulfill all document request promises
    const documentRequests = await Promise.all(documentRequestPromises);

    // Instantiate active participants object with document request ids as the keys
    documentRequests.forEach((docReq) => {
      activeParticipants[docReq.id] = ticket.clientId;
    });

    // Add document due date, updated participants to tickets collection
    await updateDoc(doc(db, 'tickets', id), {
      documentsDueDate: new Date(`${dueDate} 23:59:59`.replace(/-/g, '/')), // Due 11:59
      participants: Array.from(new Set(updatedParticipants)), // only unique participants
      activeParticipants,
    });

    // Notification
    const clientEmail = accounts.find(
      (a) => a.id === currentTicket.clientId
    ).email;
    const approverObjects = accounts.filter((account) =>
      allTicketApprovers.includes(account.id)
    );
    const approverEmails = approverObjects.map((approver) => approver.email);

    // Client Notification
    await axios.post(
      'https://us-central1-exora-crm-f9967.cloudfunctions.net/createDocumentSubmissionClient',
      {
        clientEmail,
        ticketName: currentTicket.title,
        ticketId: currentTicket.id,
      }
    );

    // Approver Notification
    await axios.post(
      'https://us-central1-exora-crm-f9967.cloudfunctions.net/createDocumentSubmissionApprover',
      {
        approverEmails,
        ticketName: currentTicket.title,
        ticketId: currentTicket.id,
      }
    );

    setIsLoading(false);

    // Navigate back
    navigation(`/tickets/${id}`);
  };

  return (
    <AppLayout>
      <div className="flex justify-between mb-12">
        <Text type="h1">Create Document Submission</Text>
        <CTAButton
          width="w-fit"
          label="Send Document Request"
          onClick={() => createRequestHandler()}
          loading={isLoading}
        />
      </div>
      <div className="rounded border border-grey p-8 mt-4">
        <Text type="h2" classnames="mb-4">
          General:
        </Text>
        <div className="flex justify-between">
          <div className="w-[49%]">
            <InputGroup
              type="date"
              min={formatDate(new Date())}
              label="Due Date"
              name="dueDate"
              value={dueDate}
              onChange={(e) => setDueDate(formatDate(e.target.value))}
            />
          </div>
        </div>
      </div>
      {requests.map((req, index) => (
        <DocumentSubmission
          key={req.id}
          requestId={req.id}
          requests={requests}
          setRequests={setRequests}
          docNumber={index + 1}
          currentTicket={currentTicket}
        />
      ))}
      <div className="flex justify-center mt-8">
        <CTAButton
          width="w-fit"
          classnames="mr-3"
          label="Delete last document"
          onClick={() => deleteDocumentRequest()}
        />
        <CTAButton
          width="w-fit"
          label="Add another document"
          onClick={() => addDocumentRequest()}
        />
      </div>
    </AppLayout>
  );
}

export default CreateSubmission;
