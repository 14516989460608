import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IAuthenticatedRouteProps {
  valid: boolean;
}

function AuthenticatedRoute({ valid }: IAuthenticatedRouteProps) {
  return valid ? <Outlet /> : <Navigate to="/" />;
}

export default AuthenticatedRoute;
